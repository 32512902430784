// src/components/CitizenImpactSidebar.js
import React, { useState } from 'react';
import './CitizenImpactSidebar.css';
import CitizenImpactDropdown from './CitizenImpactDropdown';

const CitizenImpactSidebar = () => {
  const [selectedMapType, setSelectedMapType] = useState('Citizens Impacted');

  // Options for filters based on selected map type
  const getFilterOptions = () => {
    switch (selectedMapType) {
      case 'Citizens Impacted':
        return {
          caste: ['Option A', 'Option B', 'Option C'], // Update options as needed
          gender: ['Male', 'Female', 'Other'],
          incomeRange: ['Low', 'Medium', 'High'],
          ageRange: ['0-18', '19-35', '36-50', '51+'],
          rationcardtype: ['Type A', 'Type B', 'Type C'],
          natureofwork: ['Nature A', 'Nature B', 'Nature C']
        };
      case 'MSMEs Impacted':
        return {
          abc: ['Male', 'Female', 'Other'],
          ageRange: ['0-18', '19-35', '36-50', '51+'],
          incomeRange: ['Low', 'Medium', 'High'],
          projectName: ['Project A', 'Project B', 'Project C']
        };
      default:
        return {
          caste: [],
          gender: [],
          incomeRange: [],
          ageRange: [],
          rationcardtype: [],
          natureofwork: [],
          urban: [],
          tribalstatus: [],
          pwdstatus: [],
          projectName: []
        };
    }
  };

  const {
    caste = [],
    gender = [],
    ageRange = [],
    incomeRange = [],
    rationcardtype = [],
    natureofwork = [],
    urban = [],
    tribalstatus = [],
    pwdstatus = [],
    projectName = []
  } = getFilterOptions();

  return (
    <aside className="citizen-impact-sidebar">
      <nav>
        <h1 className="citizen-impact-sidebar-header">Heat map based on</h1>
        <CitizenImpactDropdown 
          label="Select" 
          options={['Citizens Impacted', 'MSMEs Impacted']} 
          onSelect={(selected) => setSelectedMapType(selected)}
        />
        <h2 className="citizen-impact-sidebar-subheader">Filter Data By</h2>
        <ul className="citizen-impact-sidebar-list">
          {caste.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={caste} 
                header="Caste"
              />
            </li>
          )}
          <li className="citizen-impact-sidebar-item">
            <CitizenImpactDropdown 
              label="Select" 
              options={gender} 
              header="Gender"
            />
          </li>
          <li className="citizen-impact-sidebar-item">
            <CitizenImpactDropdown 
              label="Select" 
              options={ageRange} 
              header="Age Range"
            />
          </li>
          <li className="citizen-impact-sidebar-item">
            <CitizenImpactDropdown 
              label="Select" 
              options={incomeRange} 
              header="Income Range"
            />
          </li>
          {rationcardtype.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={rationcardtype} 
                header="Ration Card Type"
              />
            </li>
          )}
          {natureofwork.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={natureofwork} 
                header="Nature of Work"
              />
            </li>
          )}
          {urban.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={urban} 
                header="Urban/Rural"
              />
            </li>
          )}
          {tribalstatus.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={tribalstatus} 
                header="Tribal Status"
              />
            </li>
          )}
          {pwdstatus.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={pwdstatus} 
                header="PWD Status"
              />
            </li>
          )}
          {projectName.length > 0 && (
            <li className="citizen-impact-sidebar-item">
              <CitizenImpactDropdown 
                label="Select" 
                options={projectName} 
                header="Project Name"
              />
            </li>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default CitizenImpactSidebar;
