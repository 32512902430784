import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Map from './Map';
import './Dashboard.css';
import RightSidebar from './RightSidebar';
import { ButtonContainer } from './Button';
import Table from './Table'; // Import the Table component

const Dashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: '',
    heatmapType: ''
  });

  const [topSchemes, setTopSchemes] = useState([]);

  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        setTopSchemes(getTopSchemes(data, 'Benefit Received')); // Default to Benefit Received
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const getTopSchemes = (data, heatmapType) => {
    let filteredSchemes = [];

    switch (heatmapType) {
      case 'Application Opened':
        filteredSchemes = data.filter(item => item.OpenCount > 0);
        filteredSchemes.sort((a, b) => b.OpenCount - a.OpenCount);
        break;
      case 'Benefit Received':
        filteredSchemes = data.filter(item => parseInt(item.BV, 10) > 0);
        filteredSchemes.sort((a, b) => b.BV - a.BV);
        break;
      case 'Applications Submitted':
        filteredSchemes = data.filter(item => item.submitted > 0);
        filteredSchemes.sort((a, b) => b.submitted - a.submitted);
        break;
      case 'Benefits Unlocked':
        filteredSchemes = data.filter(item => item.BR > 0);
        filteredSchemes.sort((a, b) => b.BR - a.BR);
        break;
      default:
        filteredSchemes = data;
    }

    return filteredSchemes.slice(0, 5); // Return the top 5 schemes
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleTopSchemesChange = (newTopSchemes) => {
    setTopSchemes(newTopSchemes);
  };

  return (
    <>
      {/* Main Dashboard */}
      <div className="dashboard">
        <Sidebar 
          setFilters={handleFilterChange} 
          setTopSchemes={handleTopSchemesChange} 
        />
        <div className="main-content">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <RightSidebar topSchemes={topSchemes} />
      </div>

      {/* Table Section - Separate Div Below the Dashboard */}
      <div className="table-section">
        <Table filters={filters} />
      </div>
    </>
  );
};

export default Dashboard;
