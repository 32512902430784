import React, { useState } from 'react';
import Map from './Map';
import './ClientDashboard.css';
import RightSidebar from './ClientRightSidebar';
import { ButtonContainer } from './Button';
import ClientSidebar from './ClientSidebar';
import Cards from '../components/Cards';
import Table from './Table';
const ClientDashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: ''
  });

  const [topSchemes, setTopSchemes] = useState([]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  return (
    <div className="main-content">
      <Cards />
      <div className="clientdashboard">
        <ClientSidebar 
          setFilters={handleFilterChange} 
          onSchemesChange={setTopSchemes} 
        />
        <div className="map-container">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <RightSidebar schemes={topSchemes} />
      </div>
      <Table filters={filters} />
    </div>
  );
};

export default ClientDashboard;
