import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('Operations');

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'Planning') {
      navigate('/historical');
    } else {
      navigate('/');
    }
  };

  return (
    <header className="header">
      <h1>Sales Dashboard</h1>
      <div className="toggle-container">
        <button
          className={`toggle-button ${activeButton === 'Operations' ? 'active' : ''}`}
          onClick={() => handleClick('Operations')}
        >
          Operations
        </button>
        <button
          className={`toggle-button ${activeButton === 'Planning' ? 'active' : ''}`}
          onClick={() => handleClick('Planning')}
        >
          Planning
        </button>
      </div>
      <button className="export-button">Export</button>
    </header>
  );
};

export default Header;
