import React, { useState, useEffect } from 'react';
import Map from './Map';
import './SchemeDashboard.css';
import SchemeRightSidebar from './SchemeRightSidebar';
import { ButtonContainer } from './Button';
import SchemeSidebar from './SchemeSidebar';
import Cards from '../components/Cards';
import Table from './Table';
const SchemeDashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: ''
  });

  const [projects, setProjects] = useState([]);

  // Fetch data and filter projects based on the selected scheme
  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        if (filters.scheme) {
          const filteredProjects = data
            .filter(project => project.scheme_name === filters.scheme)
            .sort((a, b) => b.Citizen_impacted - a.Citizen_impacted) // Sort by impact
            .slice(0, 5); // Get top 5
          setProjects(filteredProjects);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [filters.scheme]);

  // Handle filter changes
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  return (
    <div className="main-content">
      <Cards />
      <div className="schemeDashboard">
        <SchemeSidebar setFilters={handleFilterChange} />
        <div className="map-container">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <SchemeRightSidebar projects={projects} />
      </div>
      <Table filters={filters} />
    </div>
  );
};

export default SchemeDashboard;
