import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HDImpactButton.css';

const HDImpactButton = ({ label, path, className, isActive }) => {
  const navigate = useNavigate();

  return (
    <button
      className={`hd-impact-dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={() => navigate(path)}
    >
      {label}
    </button>
  );
};

const HDImpactButtonContainer = () => {
  const [activeButton, setActiveButton] = useState('Project Impact');

  const buttons = [
    { label: 'Project Impact', path: '/', className: 'project-impact-hd-button' },
    { label: 'HD Impact', path: '/hd-impact', className: 'hd-impact-hd-button' },
    { label: 'Citizen Impact', path: '/citizen-impact', className: 'citizen-submitted-hd-button' },
  ];

  return (
    <div className="hd-impact-button-container">
      {buttons.map((button) => (
        <HDImpactButton
          key={button.label}
          label={button.label}
          path={button.path}
          className={button.className}
          isActive={activeButton === button.label}
          onClick={() => setActiveButton(button.label)}
        />
      ))}
    </div>
  );
};

export { HDImpactButton, HDImpactButtonContainer };
