import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';

const Button = ({ label, route, className, isActive }) => {
  const navigate = useNavigate();
  
  return (
    <button
      className={`dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={() => navigate(route)}
    >
      {label}
    </button>
  );
};

const ButtonContainer = () => {
  const [activeButton, setActiveButton] = useState('Project Impact');

  const buttons = [
    { label: 'Project Impact', route: '/', className: 'project-impact-button' },
    { label: 'HD Impact', route: '/hd-impact', className: 'hd-impact-button' },
    { label: 'Citizen Impact', route: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  return (
    <div className="button-container">
      {buttons.map((button) => (
        <Button
          key={button.label}
          label={button.label}
          route={button.route}
          className={button.className}
          isActive={activeButton === button.label}
          onClick={() => setActiveButton(button.label)}
        />
      ))}
    </div>
  );
};

export { Button, ButtonContainer };
