// src/components/CitizenImpactButton.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CitizenImpactButton.css';

const CitizenImpactButton = ({ label, path, className, isActive }) => {
  const navigate = useNavigate();

  return (
    <button
      className={`citizen-impact-dashboard-button ${className} ${isActive ? 'active' : ''}`}
      onClick={() => navigate(path)}
    >
      {label}
    </button>
  );
};

const CitizenImpactButtonContainer = () => {
  const [activeButton, setActiveButton] = useState('Project Impact');

  const buttons = [
    { label: 'Project Impact', path: '/', className: 'project-impact-citizen-button' },
    { label: 'HD Impact', path: '/hd-impact', className: 'hd-impact-citizen-button' },
    { label: 'Citizen Impact', path: '/citizen-impact', className: 'citizen-impact-button' },
  ];

  return (
    <div className="citizen-impact-button-container">
      {buttons.map((button) => (
        <CitizenImpactButton
          key={button.label}
          label={button.label}
          path={button.path}
          className={button.className}
          isActive={activeButton === button.label}
          onClick={() => setActiveButton(button.label)}
        />
      ))}
    </div>
  );
};

export { CitizenImpactButton, CitizenImpactButtonContainer };
