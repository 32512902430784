import React, { useState, useEffect } from 'react';
import Map from './Map';
import './AllFilterDashboard.css';
import RightSidebar from './AllFilterRightSidebar';
import { ButtonContainer } from './Button';
import AllFilterSidebar from './AllFilterSidebar';
import Cards from '../components/Cards'; // Import Cards component
import Table from './Table'; // Import the Table component

const AllFilterDashboard = () => {
  const [filters, setFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: ''
  });

  const [topSchemes, setTopSchemes] = useState([]);

  // Define the handleFilterChange function
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  // Apply filters to topSchemes based on the selected scheme
  const filteredSchemes = topSchemes.filter((scheme) => {
    // Only filter by scheme if it's selected (i.e., not empty)
    if (filters.scheme && filters.scheme !== scheme.scheme_name) {
      return false;
    }
    // You can add more filtering logic here for other filters if needed
    return true;
  });

  // Example useEffect to fetch schemes when filters change (if fetching from API)
  useEffect(() => {
    // Example: Fetch schemes from an API or database and update topSchemes
    // setTopSchemes(fetchedSchemes);
  }, [filters]);

  return (
    <div className="main-content">
      {/* Place Cards component outside of the main AllFilterDashboard layout */}
      <Cards />
      <div className="allfilterdashboard">
        <AllFilterSidebar setFilters={handleFilterChange} setTopSchemes={setTopSchemes} />
        <div className="map-container">
          <ButtonContainer />
          <Map filters={filters} />
        </div>
        <RightSidebar topSchemes={filteredSchemes} /> {/* Pass filtered schemes to RightSidebar */}
      </div>
      <Table filters={filters} />
    </div>
  );
};

export default AllFilterDashboard;
