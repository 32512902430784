import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Cards from './components/Cards';
import Dashboard from './components/Dashboard';
import Historical from './Historical Components/Historical';
import HDImpact from './HD Impact/HDImpactDashboard'; 
import CitizenImpact from './Citizen Impact/CitizenImpactDashboard';
//import Sidebar2 from './components/Sidebar2'; // Import Sidebar2 component
import Map2 from './Map2/SchemeDashboard';
import ClientFilter from './ClientFilter/ClientDashboard';
import AllFilters from './AllFilters/AllFilterDashboard'

const App = () => {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Cards />
              <Dashboard />
            </>
          } />
          <Route path="/historical" element={<Historical />} />
          <Route path="/hd-impact" element={<HDImpact />} />
          <Route path="/citizen-impact" element={<CitizenImpact />} />
          <Route path="/map2" element={<Map2 />} /> 
          <Route path="/clientfilter" element={<ClientFilter />} />
          <Route path="/allfilters" element={<AllFilters />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
