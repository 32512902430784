import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Dropdown from './Dropdown';

const Sidebar = ({ setFilters, setTopSchemes }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    serviceType: '',
    scheme: '',
    heatmapType: ''
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
        updateFilters(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const updateFilters = (data) => {
    const schemeNames = data.map(item => item.scheme_name);
    const projectNames = data.map(item => item.PROJECT_ID);
    const clientNames = data.map(item => item.Project_Name);
    const serviceTypeNames = data.map(item => item["Type(Sch/Doc)"]);
    const projectTypeNames = data.map(item => item.Project_Type);

    setSchemes([...new Set(schemeNames)]);
    setProjects([...new Set(projectNames)]);
    setClients([...new Set(clientNames)]);
    setServiceTypes([...new Set(serviceTypeNames)]);
    setProjectTypes([...new Set(projectTypeNames)]);
  };

  const getTopSchemes = (filteredData, heatmapType) => {
    let sortedSchemes = [...filteredData];
    switch (heatmapType) {
      case 'Application Opened':
        sortedSchemes.sort((a, b) => b.OpenCount - a.OpenCount);
        break;
      case 'Benefit Received':
        sortedSchemes.sort((a, b) => b.BV - a.BV);
        break;
      case 'Applications Submitted':
        sortedSchemes.sort((a, b) => b.submitted - a.submitted);
        break;
      case 'Benefits Unlocked':
        sortedSchemes.sort((a, b) => b.BR - a.BR);
        break;
      default:
        break;
    }
    return sortedSchemes.slice(0, 5);
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: value
    }));
    setFilters(filterKey, value);
  
    let newFilteredData = data;
  
    if (filterKey === 'heatmapType') {
      newFilteredData = data.filter(item => {
        switch (value) {
          case 'Application Opened':
            return item.OpenCount > 0;
          case 'Benefit Received':
            return parseInt(item.BV, 10) > 0;
          case 'Applications Submitted':
            return item.submitted > 0;
          case 'Benefits Unlocked':
            return item.BR > 0;
          default:
            return true;
        }
      });
  
      setFilteredData(newFilteredData);
      setTopSchemes(getTopSchemes(newFilteredData, value));
    } else {
      // Handle other filter changes...
    }
  };
  
  const handleClearFilter = (filterKey) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: ''
    }));
    setFilters(filterKey, '');

    const newFilteredData = data.filter(item => {
      return Object.keys(selectedFilters).every(key => {
        if (key === filterKey) return true;
        if (!selectedFilters[key]) return true;
        switch (key) {
          case 'client':
            return item.Project_Name === selectedFilters[key];
          case 'project':
            return item.PROJECT_ID === selectedFilters[key];
          case 'projectType':
            return item.Project_Type === selectedFilters[key];
          case 'serviceType':
            return item["Type(Sch/Doc)"] === selectedFilters[key];
          case 'scheme':
            return item.scheme_name === selectedFilters[key];
          default:
            return true;
        }
      });
    });

    setFilteredData(newFilteredData);
    setTopSchemes(getTopSchemes(newFilteredData, selectedFilters.heatmapType));
  };

  const filterOptions = {
    client: clients,
    project: projects,
    projectType: projectTypes,
    serviceType: serviceTypes,
    scheme: schemes
  };

  return (
    <aside className="sidebar">
      <nav>
        <h1 className="sidebar-header">Heat map based on</h1>
        <Dropdown
          label="Select"
          options={['Application Opened', 'Benefit Received', 'Applications Submitted', 'Benefits Unlocked']}
          onSelect={(value) => handleFilterChange('heatmapType', value)}
          selectedOption={selectedFilters.heatmapType}
        />
        <button className="add-filters-btn" onClick={() => navigate('/map2')}>
         View Data By Schemes
        </button>
        <button className="new-button" onClick={() => navigate('/clientfilter')}>
          View Data By Clients 
        </button>
        <button className="all-filters-button" onClick={() => navigate('/allfilters')}>
        Schemes/Clients
        </button>
      </nav>
    </aside>
  );
};

export default Sidebar;
