import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './HDImpactMap.css';
import L from 'leaflet';

const HDImpactMap = () => {
  const [geoData, setGeoData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    // Fetch the GeoJSON data
    fetch('/india.json')
      .then(response => response.json())
      .then(data => {
        setGeoData(data);
        if (mapRef.current) {
          const map = mapRef.current;
          const bounds = L.geoJSON(data).getBounds();
          map.fitBounds(bounds);
        }
      })
      .catch(error => console.error('Error loading the GeoJSON data:', error));

    // Fetch the state data
    fetch('/Map.json')
      .then(response => response.json())
      .then(data => setStateData(data))
      .catch(error => console.error('Error loading the state data:', error));
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      map.setView([22.9734, 82.6], 4.5);
    }
  }, [geoData, stateData]);

  const getColor = (value) => {
    return value > 1000000 ? '#ab65f8' :
           value > 500000  ? '#b87df9' :
           value > 200000  ? '#b87df9' :
           value > 100000  ? '#c595fa' :
           value > 50000   ? '#c595fa' :
           value > 20000   ? '#dabcfc' :
           value > 10000   ? '#efe3fd' :
                            '#f4ebfe';
  };

  const style = (feature) => {
    const state = stateData.find(s => s.st_nm === feature.properties.st_nm);
    return {
      fillColor: state ? getColor(state.benefitsUnlocked) : '#FFEDA0',
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      interactive: true
    };
  };

  const onEachFeature = (feature, layer) => {
    const state = stateData.find(s => s.st_nm === feature.properties.st_nm);
    if (state) {
      const popupContent = `
        <b>${state.st_nm}</b><br/>
        Citizens impacted: ${state.citizensImpacted.toLocaleString()}<br/>
        Applications submitted: ${state.applicationsSubmitted.toLocaleString()}<br/>
        MSMEs impacted: ${state.msmesImpacted.toLocaleString()}<br/>
        HDs trained: ${state.hdsTrained.toLocaleString()}<br/>
        Benefits unlocked: ₹${state.benefitsUnlocked.toLocaleString()}
      `;
      layer.bindPopup(popupContent);
    }
  };

  return (
    <div className="hd-impact-map">
      <MapContainer
        center={[22.9734, 82.6]} // Shifted longitude to the left
        zoom={5} // Initial integer zoom level
        style={{ height: '100%', width: '100%' }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
          mapInstance.setView([22.9734, 82.6], 4.5); // Set fractional zoom level
        }}
        dragging={false} // Disable dragging
        scrollWheelZoom={false} // Disable scroll wheel zoom
        doubleClickZoom={false} // Disable double click zoom
        zoomControl={false} // Disable zoom control
        touchZoom={false} // Disable touch zoom
        boxZoom={false} // Disable box zoom
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {geoData && stateData.length > 0 && (
          <GeoJSON data={geoData} style={style} onEachFeature={onEachFeature} />
        )}
      </MapContainer>
    </div>
  );
};

export default HDImpactMap;
