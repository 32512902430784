// src/components/HDImpactSidebar.js
import React from 'react';
import './HDImpactSidebar.css';
import HDImpactDropdown from './HDImpactDropdown';

const HDImpactSidebar = () => {
  return (
    <aside className="hd-impact-sidebar">
      <nav>
        <h1 className="hd-impact-sidebar-header">Heat map based on</h1>
        <HDImpactDropdown 
          label="Select" 
          options={['Option 1', 'Option 2', 'Option 3']} 
          //header="Heat map based on"
        />
        <h2 className="hd-impact-sidebar-subheader">Filter Data By</h2>
        <ul className="hd-impact-sidebar-list">
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown 
              label="Select" 
              options={['Male', 'Female', 'Other']} 
              header="Gender"
            />
          </li>
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown 
              label="Select" 
              options={['Option 1', 'Option 2', 'Option 3']} 
              header="Age Range"
            />
          </li>
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown 
              label="Select" 
              options={['Option 1', 'Option 2', 'Option 3']} 
              header="Income Range"
            />
          </li>
          <li className="hd-impact-sidebar-item">
            <HDImpactDropdown 
              label="Select" 
              options={['Option 1', 'Option 2', 'Option 3']} 
              header="Project Name"
            />
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default HDImpactSidebar;
