import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import L from 'leaflet';

const Map = ({ filters }) => {
  const [geoData, setGeoData] = useState(null);
  const [filteredStateData, setFilteredStateData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const mapRef = useRef(null);
  const layerRef = useRef(null);

  // Fetch GeoJSON data
  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch('/india.json');
        if (!response.ok) throw new Error('Failed to load GeoJSON data');
        const data = await response.json();
        setGeoData(data);

        if (mapRef.current) {
          const bounds = L.geoJSON(data).getBounds();
          mapRef.current.fitBounds(bounds);
        }
      } catch (error) {
        console.error('Error loading the GeoJSON data:', error);
      }
    };

    fetchGeoData();
  }, []);

  // Fetch and filter state data based on filters
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await fetch('/project_data.json');
        if (!response.ok) throw new Error('Failed to load project data');
        const data = await response.json();

        // Ensure all filter conditions are applied correctly
        const filteredData = data.filter(item => {
          return (
            (filters.project ? item.PROJECT_ID === filters.project : true) &&
            (filters.client ? item.Project_Name === filters.client : true) &&
            (filters.projectType ? item.Project_Type === filters.projectType : true) &&
            (filters.serviceType ? item["Type(Sch/Doc)"] === filters.serviceType : true) &&
            (filters.scheme ? item.scheme_name === filters.scheme : true) // Make sure scheme name matches the selected filter
          );
        });

        setFilteredStateData(filteredData);
        setSelectedState(null); // Reset selected state when filters change

        console.log("Filtered data:", filteredData); // Log the filtered data here to check
      } catch (error) {
        console.error('Error loading the state data:', error);
      }
    };

    fetchStateData();
  }, [filters]);

  const getColor = (value, type) => {
    console.log(`Value: ${value}, Type: ${type}`); // Add this line
    
    switch (type) {
      case 'Application Opened':
        return value > 5000000 ? '#880ED4' :
               value > 1000000 ? '#A020F0' :
               value > 500000 ? '#B24BF3' :
               value > 100000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'Applications Submitted':
        return value > 4000000 ? '#880ED4' :
               value > 2000000 ? '#A020F0' :
               value > 1000000 ? '#B24BF3' :
               value > 500000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'Benefits Unlocked':
        return value > 100000000000 ? '#6C0BA9' :
               value > 50000000000 ? '#880ED4' :
               value > 10000000000 ? '#A020F0' :
               value > 1000000000 ? '#B24BF3' :
               value > 100000000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'Citizens Impacted':
        return value > 4000000 ? '#880ED4' :
               value > 2000000 ? '#A020F0' :
               value > 1000000 ? '#B24BF3' :
               value > 500000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'MSMEs Impacted':
        return value > 60000 ? '#880ED4' :
               value > 40000 ? '#A020F0' :
               value > 20000 ? '#B24BF3' :
               value > 10000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'HDs Trained':
        return value > 30000 ? '#880ED4' :
               value > 20000 ? '#A020F0' :
               value > 10000 ? '#B24BF3' :
               value > 5000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';
      case 'Benefit Received':
        return value > 30000 ? '#880ED4' :
               value > 20000 ? '#A020F0' :
               value > 10000 ? '#B24BF3' :
               value > 5000 ? '#C576F6' :
               value > 0 ? '#D7A1F9' :
                           '#cccccc';                     
      default:
        return '#cccccc';
    }
  };
  

  const style = (feature) => {
    const state = filteredStateData.find(
      (item) => item.st_nm === feature.properties.st_nm
    );
    const type = filters.heatmapType;
  
    let value = 0;
    if (state) {
      switch (type) {
        case 'Application Open':
          value = state.OpenCount || 0;
          break;
        case 'Applications Submitted':
          value = state.submitted || 0;
          break;
        case 'Benefit Received':
          value = state.BR || 0;
          break;
        default:
          value = 0;
      }
    }
  
    // Define clientName here
    const clientName = filters.client;
  
    const fillColor = getColor(value, type);
  
    return {
      fillColor: clientName && state?.Project_Name === clientName ? '#C576F6' : fillColor,
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '2',
      fillOpacity: 0.7,
      interactive: true,
    };
  };
  
  const onEachFeature = (feature, layer) => {
    const st_nm = feature.properties.st_nm;
    // Find the correct state data matching the current feature (state boundary)
    const state = filteredStateData.find(item => item.st_nm === st_nm && item.scheme_name === filters.scheme);
  
    const popupContent = state
      ? `
        <div class="popup-container">
          <h3 class="state-name">${st_nm}</h3>
          <ul class="stats-list">
            <li>Application opened: <span>${(state.OpenCount || 0).toLocaleString()}</span></li>
            <li>Applications submitted: <span>${(state.submitted || 0).toLocaleString()}</span></li>
            <li>Benefit received: <span>${(state.BR || 0).toLocaleString()}</span></li>
            <li>Benefits unlocked: <span>₹${(state.BV || 0).toLocaleString()}</span></li>
           

            </ul>
        </div>
      `
      : `<div class="popup-container"><h3 class="state-name">${st_nm}</h3><p>No data available</p></div>`;
  
    layer.bindPopup(popupContent);
  
    layer.on({
      click: () => {
        setSelectedState(feature);
        layer.openPopup();
      }
    });
  
    if (!selectedState && !layerRef.current) {
      layerRef.current = layer;
      layer.openPopup();
    }
  };
  
  

  return (
    <div className="map">
      <MapContainer
        center={[22.9734, 82.6]}
        zoom={5}
        style={{ height: '100%', width: '100%' }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
          mapInstance.setView([22.9734, 82.6], 4.5);
        }}
      >
        {geoData && (
          <GeoJSON
            key={JSON.stringify(filters)} // Re-render on filter change
            data={geoData}
            style={style}
            onEachFeature={onEachFeature}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;




