// src/components/CitizenImpactDashboard.js
import React from 'react';
import CitizenImpactCards from './CitizenImpactCards'; // Import the CitizenImpactCards component
import './CitizenImpactDashboard.css';
import CitizenImpactSidebar from './CitizenImpactSidebar';
import CitizenImpactMap from './CitizenImpactMap'; // Commented out CitizenImpactMap
import CitizenImpactRightSidebar from './CitizenImpactRightSidebar'; // Commented out CitizenImpactRightSidebar
import { CitizenImpactButtonContainer } from './CitizenImpactButton'; // Commented out CitizenImpactButtonContainer

const CitizenImpactDashboard = () => {
  return (
    <div className="citizenimpact-main-content">
      <CitizenImpactCards />
      <div className="citizenimpact-dashboard">
        <CitizenImpactSidebar /> {/* Uncommented CitizenImpactSidebar */}
        <div className="citizenimpact-map-container">
          <CitizenImpactButtonContainer /> 
          <CitizenImpactMap />
        </div>
        { <CitizenImpactRightSidebar /> }
      </div>
    </div>
  );
};

export default CitizenImpactDashboard;
