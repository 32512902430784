// src/components/HDImpactDashboard.js
import React from 'react';
//import Sidebar from './Sidebar';
import HDImpactCards from './HDImpactCards'; // Import the HDImpactCards component
import './HDImpactDashboard.css';
import HDImpactSidebar from './HDImpactSidebar';
import HDImpactMap from './HDImpactMap';
import HDImpactRightSidebar from './HDImpactRightSidebar';
import { HDImpactButtonContainer } from './HDImpactButton';
//import RightSidebar from './RightSidebar';

const HDImpactDashboard = () => {
  return (
    <div className="hdimpact-main-content">
      <HDImpactCards />
      <div className="hdimpact-dashboard">
        <HDImpactSidebar />
        <div className="hdimpact-map-container">
          <HDImpactButtonContainer /> {/* Add the button container here */}
          <HDImpactMap />
        </div>
        <HDImpactRightSidebar />
      </div>
    </div>
  );
};

export default HDImpactDashboard;
