// src/components/CitizenImpactRightSidebar.js
import React from 'react';
import './CitizenImpactRightSidebar.css';

const CitizenImpactRightSidebar = () => {
  const schemes = [
    {
      title: 'PM Krishi Yojana',
      category: 'Agriculture',
      amount: '10 Lakh',
      duration: '20-25 days'
    },
    {
      title: 'Kanyashree Scheme',
      category: 'Agriculture',
      amount: '10 Lakh',
      duration: '20-25 days'
    },
    {
      title: 'Dhan Yan Yojana',
      category: 'Agriculture',
      amount: '10 Lakh',
      duration: '20-25 days'
    },
    {
      title: 'PM Krishi Yojana',
      category: 'Agriculture',
      amount: '10 Lakh',
      duration: '20-25 days'
    }
  ];

  return (
    <div className="citizenimpact-rightsidebar">
      <header className="citizenimpact-header">
        <div className="citizenimpact-header-icon">
          <img src="/Folder Briefcase.jpg" alt="Home Icon" />
        </div>
        <div className="citizenimpact-header-text">
          <h2>Tata Sampan</h2>
        </div>
        <button className="citizenimpact-header-arrow-btn">
          <img src="/Arrow Right.jpg" alt="Arrow Icon" />
        </button>
      </header>
      <h3 className="citizenimpact-top-schemes-title">Top Schemes Delivered</h3>
      <p className="citizenimpact-location">All India</p>
      <div className="citizenimpact-content">
        {schemes.map((scheme, index) => (
          <div key={index} className="citizenimpact-card">
            <h4 className="citizenimpact-card-title">{scheme.title}</h4>
            <p className="citizenimpact-card-category">{scheme.category}</p>
            <div className="citizenimpact-card-info">
              <div className="citizenimpact-icon">
                <img src="/Money.jpg" alt="Amount Icon" />
              </div>
              <span className="citizenimpact-amount">{scheme.amount}</span>
            </div>
            <div className="citizenimpact-card-info">
              <div className="citizenimpact-icon">
                <img src="/Hourglass Three Quarter.jpg" alt="Duration Icon" />
              </div>
              <span className="citizenimpact-duration">{scheme.duration}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CitizenImpactRightSidebar;
